import * as Sentry from '@sentry/nextjs';
import { backOff } from 'exponential-backoff';
import Stripe from 'stripe';
import { EMPTY_CUSTOMER } from 'utilities/stripe/helpers';

import { normalizePaymentMethod } from 'utilities/stripe/paymentMethod';

const STRIPE_API_VERSION = process.env.STRIPE_API_VERSION;
// Right now this will fallback to a stage key, but closer to live we may want logic to select one.
const STRIPE_SECRET_TOKEN =
  process.env.STRIPE_SECRET_TOKEN_PROD || process.env.STRIPE_SECRET_TOKEN_STAGE;
const STRIPE_PRODUCT_ID = process.env.STRIPE_PRODUCT_ID;
// This is the only one that ends up in the client build.
const STRIPE_PUBLISHABLE_TOKEN =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_TOKEN_PROD ||
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_TOKEN_STAGE;

// This is not really documented but how it works is https://github.com/stripe/stripe-node/blob/56e5f32b305737ccd535902276bd06d59a3f419b/src/stripe.core.ts#L27
const STRIPE_APP_INFO = Object.freeze({
  name: 'Gin Rickey',
  url: 'https://github.com/resistbot/ginrickey',
  version: process.env.COMMIT_REF,
});

const stripe = new Stripe(STRIPE_SECRET_TOKEN, {
  apiVersion: STRIPE_API_VERSION,
  appInfo: STRIPE_APP_INFO,
  maxNetworkRetries: 5,
  timeout: 50000, // Vercel Times out at 50s, maybe we can get one more in before it quits, defaults to 80s.
});

const PROMOTE_TIERS_CENTS = [300, 500, 1000, 2500, 5000, 10000, 25000, 50000];
const RECURRING_PERIODS = Object.freeze({
  MONTH: 'month',
  YEAR: 'year',
});

const getStripeAppearanceConfig = ({ darkMode = false }) => {
  // Configure theme options
  // https://stripe.com/docs/elements/appearance-api
  const sansFamily =
    'typold, -apple-system, blinkmacsystemfont, segoe ui, helvetica, arial, sans-serif, apple color emoji, segoe ui emoji';
  const appearance = darkMode
    ? {
        theme: 'night',
        // https://stripe.com/docs/elements/appearance-api?platform=web#variables
        variables: {
          colorPrimary: '#ffc20e',
          fontFamily: sansFamily,
        },
        // https://stripe.com/docs/elements/appearance-api?platform=web#rules
        rules: {},
      }
    : {
        theme: 'stripe',
        // https://stripe.com/docs/elements/appearance-api?platform=web#variables,
        variables: {
          colorPrimary: '#0092ff',
          fontFamily: sansFamily,
        },
        // https://stripe.com/docs/elements/appearance-api?platform=web#rules
        rules: {},
      };
  return appearance;
};

const getStripeFontConfig = () => {
  // https://stripe.com/docs/js/appendix/custom_font_source_object

  // NOTE: These are loaded in the iFrame and generally fetch world-readable resources
  // The way we currently configure SITE_URL this generally will not try to load local resources (it could though)
  const fonts = [
    {
      family: 'Typold',
      src: `url(${process.env.SITE_URL}/fonts/TypoldBook500/font.woff2)`,
      weight: '500',
    },
    {
      family: 'Typold',
      src: `url(${process.env.SITE_URL}/fonts/TypoldBlack/font.woff2)`,
      weight: '800',
    },
  ];
  return fonts;
};

const getResistbotPrices = async ({ uuid = null }) => {
  if (uuid) {
    Sentry.setUser({ id: uuid });
  }

  const resp = await stripe.prices.list({
    product: STRIPE_PRODUCT_ID,
    active: true,
    type: 'recurring',
    limit: 2,
  });
  const prices = resp.data;

  let month;
  let year;

  prices.forEach((price) => {
    if (price.recurring.interval === RECURRING_PERIODS.MONTH) {
      month = {
        id: price.id,
        unitAmount: price.unit_amount,
        formattedAmount: `${price.unit_amount / 100}`,
      };
    } else if (price.recurring.interval === RECURRING_PERIODS.YEAR) {
      year = {
        id: price.id,
        unitAmount: price.unit_amount,
        formattedAmount: `${price.unit_amount / 100}`,
      };
    } else {
      throw new Error('Unexpected price interval');
    }
  });

  if (!month || !year) {
    throw new Error('Missing a required month or year price');
  }

  return {
    month,
    year,
  };
};

const getPromoteTiers = () => {
  return PROMOTE_TIERS_CENTS;
};

const getCustomerForUuid = async (uuid) => {
  const resCustomer = await stripe.customers.search({
    query: `metadata['uuid']:'${uuid}'`,
    expand: [
      'data.invoice_settings.default_payment_method',
      'data.subscriptions',
      'data.default_source',
    ],
  });

  const stripeCustomer = resCustomer.data[0] || null;
  if (!stripeCustomer) {
    // raise an error to trigger a retry
    throw new Error('Customer not found');
  }
  return stripeCustomer;
};

const getCustomerForId = async ({ customerId }) => {
  return await stripe.customers.retrieve(customerId, {
    expand: [
      'invoice_settings.default_payment_method',
      'subscriptions',
      'default_source',
    ],
  });
};

// TODO: Improve the logic for dealing with multiple customers with the same UUID
const getExistingCustomer = async ({
  uuid,
  customerId = null,
  retryOnEmpty = true,
}) => {
  if (!uuid && !customerId) {
    return EMPTY_CUSTOMER;
  }

  if (uuid) {
    Sentry.setUser({ id: uuid });
  }
  if (customerId) {
    Sentry.getCurrentScope().setExtra('stripe.customerId', customerId);
  }

  let stripeCustomer = null;
  const directStartTime = Date.now();
  let directAttempts = 0;
  let directElapsedTime = 0;
  if (customerId) {
    // Perform the typical stripe customer lookup
    try {
      directAttempts += 1;
      stripeCustomer = await getCustomerForId({ customerId });
    } catch (e) {
      // Note it, continue
      Sentry.captureException(e);
    }
  }
  directElapsedTime = Date.now() - directStartTime;

  // There is a couple second delay for a new customer to appear in search.
  // If we're sending people here _immediately_ after creating the user, this might fail.
  const startTime = Date.now();
  let attempts = 0;
  let elapsedTime = 0;

  if (!stripeCustomer) {
    if (retryOnEmpty) {
      try {
        stripeCustomer = await backOff(() => getCustomerForUuid(uuid), {
          retry: (e, attemptNumber) => {
            attempts = attemptNumber;
            elapsedTime = Date.now() - startTime;
            // eslint-disable-next-line no-console
            console.log(
              `Attempt ${attemptNumber} failed: (${e}), time since starting: ${elapsedTime}s Retrying...`
            );
            return true;
          },
          delayFirstAttempt: false,
          jitter: 'full',
          maxDelay: 1000,
          numOfAttempts: 5,
        });
        attempts += 1;
        elapsedTime = Date.now() - startTime;
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } else {
      try {
        stripeCustomer = await getCustomerForUuid(uuid);
        // eslint-disable-next-line no-empty
      } catch (e) {}
      attempts = 1;
      elapsedTime = Date.now() - startTime;
    }
  }
  if (!stripeCustomer) {
    const emptyCustomer = { ...EMPTY_CUSTOMER };
    emptyCustomer.attempts.search.attempts = attempts;
    emptyCustomer.attempts.search.time = elapsedTime;
    emptyCustomer.attempts.direct.attempts = directAttempts;
    emptyCustomer.attempts.direct.time = directElapsedTime;
    return emptyCustomer;
  }

  const subscriptions = stripeCustomer.subscriptions.data;
  const subscription = subscriptions[0];

  // Notify Sentry if a customer has more than one subscription
  if (subscriptions.length > 1) {
    Sentry.captureException(
      new Error(`Customer ${stripeCustomer.id} has more than one subscription`)
    );
  }

  let setupIntent = {
    clientSecret: null,
  };

  if (
    !stripeCustomer.invoice_settings.default_payment_method &&
    !stripeCustomer.default_source
  ) {
    const stripeSetupIntent = await stripe.setupIntents.create({
      customer: stripeCustomer.id,
      automatic_payment_methods: {
        enabled: true,
      },
    });

    setupIntent = {
      clientSecret: stripeSetupIntent.client_secret,
    };
  }

  // Fetch the billing portal session link
  const portalSession = await stripe.billingPortal.sessions.create({
    customer: stripeCustomer.id,
    return_url: `${process.env.SITE_URL}/premium`,
  });

  const customer = {
    id: stripeCustomer.id,
    email: stripeCustomer.email,
    uuid: stripeCustomer.metadata.uuid,
    subscription: null,
    billingPortalSession: portalSession.url,
  };

  // Append the subscription data
  if (subscription) {
    customer.subscription = {
      id: subscription.id,
      active: subscription.status === 'active',
      cancelAt: subscription.cancel_at,
      currentPeriodEnd: subscription.current_period_end,
      plan: {
        id: subscription.plan.id,
        amount: subscription.plan.amount,
        amountFormatted: `${subscription.plan.amount / 100}`,
        interval: subscription.plan.interval,
      },
    };
  }

  // Configure the payment method data
  const paymentMethod = normalizePaymentMethod({
    defaultPaymentMethod:
      stripeCustomer.invoice_settings.default_payment_method,
    defaultSource: stripeCustomer.default_source,
  });

  return {
    customer,
    paymentMethod,
    setupIntent,
    attempts: {
      search: {
        attempts,
        time: elapsedTime,
      },
      direct: {
        attempts: directAttempts,
        time: directElapsedTime,
      },
    },
  };
};

export {
  getStripeAppearanceConfig,
  getStripeFontConfig,
  getResistbotPrices,
  getExistingCustomer,
  getPromoteTiers,
  stripe,
  STRIPE_PUBLISHABLE_TOKEN,
  RECURRING_PERIODS,
};
