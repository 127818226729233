// TODO: Link, CashApp, etc.

// These tend to be the Legacy types.
const PAYMENT_TYPES = {
  // Legacy Cards, Sources
  CARD: 'card',
  BANK_ACCOUNT: 'bank_account',
  // PaymentMethods are identified with this, and have special fields after that/
  PAYMENT_METHOD: 'payment_method', // This is the _object type_
};

// Another object to handle all the differet types for a PaymentMethod
const PAYMENT_METHOD_PAYMENT_TYPES = {
  CARD: 'card',
  US_BANK_ACCOUNT: 'us_bank_account',
  LINK: 'link',
  CASH_APP: 'cashapp',
};

const MINIMUM_TRANSACTION_AMOUNT_CENTS = 199;
const MAXIMUM_TRANSACTION_AMOUNT_CENTS = 50000; // Nominally we use $299.00 as a peak
const MAXIMUM_TIP_PERCENT = 100; // Could go higher, but prevent really bad cases.

export {
  PAYMENT_TYPES,
  PAYMENT_METHOD_PAYMENT_TYPES,
  MAXIMUM_TIP_PERCENT,
  MAXIMUM_TRANSACTION_AMOUNT_CENTS,
  MINIMUM_TRANSACTION_AMOUNT_CENTS,
};
