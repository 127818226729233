'use client';

import Button from 'components/buttons/button';
import signout from 'images/icons/log-out.svg';
import fetchJson from 'lib/fetchJson';
import useUser from 'lib/useUser';
import { useRouter } from 'next/navigation';

export const LogoutButton = () => {
  const router = useRouter();
  const { user, mutateUser } = useUser();
  return user?.isLoggedIn ? (
    <Button
      href="/api/account/logout"
      preventDefault={true}
      theme={'navigation'}
      iconSrc={signout}
      onClick={async () => {
        mutateUser(
          await fetchJson('/api/account/logout', { method: 'POST' }),
          false
        );
        // Redirect to homepage
        router.push('/');
      }}
    >
      Sign Out
    </Button>
  ) : null;
};

export default LogoutButton;
