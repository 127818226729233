import PropTypes from 'prop-types';

import Component from './component';
import cx from 'classnames';

const Container = ({ className, children, ...props }) => {
  return (
    <Component
      className={cx('mx-auto max-w-screen-lg px-6', className)}
      {...props}
    >
      {children}
    </Component>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Container;
