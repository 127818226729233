import cx from 'classnames';
import PropTypes from 'prop-types';

import Component from './component';

const BASE_CLASSES =
  'font-normal hyphens-none whitespace-nowrap uppercase font-mono tracking-wide bg-stone-200 text-stone-700 dark:bg-stone-700 dark:text-stone-300 rounded-lg px-2 py-.5';

const Bubble = ({ as = 'span', className = '', ...props }) => {
  return (
    <Component as={as} className={cx(BASE_CLASSES, className)} {...props} />
  );
};

Bubble.propTypes = {
  as: PropTypes.node,
  className: PropTypes.string,
};

export default Bubble;
