import { createElement } from 'react';
import PropTypes from 'prop-types';

const Component = ({ as = 'div', children, ...props }) => {
  return createElement(as, props, children);
};

Component.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Component;
