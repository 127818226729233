import * as Sentry from '@sentry/nextjs';

import { PAYMENT_TYPES, PAYMENT_METHOD_PAYMENT_TYPES } from './config';
import { UnknownPaymentMethodError } from './errors';

// This is a bit of a legacy object, so we convert it to the lowercased
// variant in use now with PaymentMethods.
// https://stripe.com/docs/api/cards
const convertLegacyCardToPaymentMethod = (cardBrand) => {
  switch (cardBrand) {
    case 'American Express':
      return 'amex';
    case 'Diners Club':
      return 'diners';
    case 'Discover':
      return 'discover';
    case 'Eftpos Australia':
      return 'eftpos';
    case 'JCB':
      return 'jcb';
    case 'MasterCard':
      return 'mastercard';
    case 'UnionPay':
      return 'unionpay';
    case 'Visa':
      return 'visa';
    default:
      return cardBrand;
  }
};

// Customer payment info can be in two different places depending if they used the legacy Card or a newer PaymentMethod
const normalizePaymentMethod = ({ defaultPaymentMethod, defaultSource }) => {
  // First we need to check if we have a default method, prioritizing default_payment_method, then default_source.
  // These could be a Card, a PaymentMethod, or a Source - any of which could be a card, bank account, etc.
  // We need to check for the existence of the card property to determine if it's a card.
  const paymentMethod = defaultPaymentMethod || defaultSource;

  Sentry.addBreadcrumb({
    category: 'stripe:parsePaymentMethod',
    message: 'Parse payment method data to normalized format',
    data: {
      defaultPaymentMethod,
      defaultSource,
    },
  });

  if (!paymentMethod) {
    return null;
  }

  // Legacy Payment Types such as Card, and Source
  if (paymentMethod.object === PAYMENT_TYPES.CARD) {
    return {
      type: PAYMENT_TYPES.CARD,
      id: paymentMethod.id,
      brand: convertLegacyCardToPaymentMethod(paymentMethod.brand),
      last4: paymentMethod.last4,
      expMonth: paymentMethod.exp_month,
      expYear: paymentMethod.exp_year,
      wallet: paymentMethod.wallet,
    };
  }

  if (paymentMethod.object === PAYMENT_TYPES.BANK_ACCOUNT) {
    return {
      type: PAYMENT_TYPES.BANK_ACCOUNT,
      id: paymentMethod.id,
      accountHolderType: paymentMethod.account_holder_type,
      accountType: paymentMethod.account_type,
    };
  }

  // These are for the modern "PaymentMethod" types.
  if (paymentMethod.object === PAYMENT_TYPES.PAYMENT_METHOD) {
    const paymentMethodPaymentType = paymentMethod.type;
    if (paymentMethodPaymentType === PAYMENT_METHOD_PAYMENT_TYPES.CARD) {
      return {
        type: PAYMENT_METHOD_PAYMENT_TYPES.CARD,
        paymentMethod: true,
        id: paymentMethod.id,
        brand: paymentMethod.card.brand,
        last4: paymentMethod.card.last4,
        expMonth: paymentMethod.card.exp_month,
        expYear: paymentMethod.card.exp_year,
        funding: paymentMethod.card.funding,
        wallet: paymentMethod.card.wallet,
      };
    }
    if (
      paymentMethodPaymentType === PAYMENT_METHOD_PAYMENT_TYPES.US_BANK_ACCOUNT
    ) {
      return {
        type: PAYMENT_METHOD_PAYMENT_TYPES.US_BANK_ACCOUNT,
        paymentMethod: true,
        id: paymentMethod.id,
        accountHolderType: paymentMethod.us_bank_account.account_holder_type,
        accountType: paymentMethod.us_bank_account.account_type,
        last4: paymentMethod.us_bank_account.last4,
        routingNumber: paymentMethod.us_bank_account.routing_number,
        bankName: paymentMethod.us_bank_account.bank_name,
      };
    }
    if (paymentMethodPaymentType === PAYMENT_METHOD_PAYMENT_TYPES.LINK) {
      return {
        id: paymentMethod.id,
        type: PAYMENT_METHOD_PAYMENT_TYPES.LINK,
        paymentMethod: true,
        email: paymentMethod.link.email,
        persistentToken: paymentMethod.link.persistent_token || null, // This is undefined in test mode, it seems
      };
    }
    if (paymentMethodPaymentType === PAYMENT_METHOD_PAYMENT_TYPES.CASH_APP) {
      return {
        id: paymentMethod.id,
        type: PAYMENT_METHOD_PAYMENT_TYPES.CASH_APP,
        paymentMethod: true,
        // There is no more detail on this method.
      };
    }
  }

  // Report to sentry a type we haven't seen before
  Sentry.captureException(
    new UnknownPaymentMethodError(
      paymentMethod.object,
      paymentMethod.type,
      `Unexpected \`${paymentMethod.object}\` type \`${paymentMethod.type}\``
    )
  );

  return null;
};

export { normalizePaymentMethod as normalizePaymentMethod };
