'use client';

import * as Sentry from '@sentry/nextjs';

import PropTypes from 'prop-types';
import NextLink from 'next/link';

import { trackEvent } from 'utilities/analytics';

const Anchor = ({
  children,
  className = '',
  href,
  label = null,
  eventName = null,
  prefetch = true, // The default behavior of next/link is to prefetch
  ...rest
}) => {
  const isInternal = /^\/(?!\/)/.test(href);
  const aTagProps = isInternal ? {} : { rel: 'noreferrer' };

  if (label) {
    aTagProps['aria-label'] = label;
  }

  const onClickFns = [];

  // Existing uses provide this prop directly, so add those first.
  if (rest.onClick) {
    onClickFns.push(rest.onClick);
  }

  // Add in the analytics tracking function when an event ID is provided.
  if (eventName) {
    onClickFns.push(() => {
      trackEvent({ eventName });
    });
  }

  // We will now run all of these as needed rather than a single one passed in
  const onClick = () => {
    onClickFns.forEach((fn) => fn());
  };

  // https://github.com/vercel/next.js/issues/16107
  // Empty urls need a better default behavior
  // Server:
  // Error occurred prerendering page "/news/2021/02/09/the-vote". Read more: https://nextjs.org/docs/messages/prerender-error
  // TypeError: Cannot destructure property 'auth' of 'urlObj' as it is undefined.
  // Client:
  // Error: Failed prop type: The prop `href` expects a `string` or `object` in `<Link>`, but got `undefined` instead.

  // All of this attempts to log the details, and then render an anchor tag
  // Which while not great, won't cause rendering to fail.
  if (
    (typeof href === 'object' || href === '' || !href) &&
    process.env.NODE_ENV !== 'production'
  ) {
    return failedAnchor({
      children,
      className,
      href,
      label,
      eventName,
      rest,
      aTagProps,
      onClick,
      prefetch,
    });
  }

  return (
    <Sentry.ErrorBoundary
      fallback={<Fallback child={children} href={href}></Fallback>}
      // onError={(e) => {
      //   console.log({ error: e, href, children, className });
      // }}
      beforeCapture={(scope) => {
        scope.setContext('anchor', { href, children, className });
      }}
    >
      <NextLink
        href={href}
        passHref
        className={className}
        {...rest}
        {...aTagProps}
        onClick={onClick}
        // Should only ever be false, or left off completely so NextJS doesn't warn about it.
        {...(prefetch === false ? { prefetch } : {})}
      >
        {children}
      </NextLink>
    </Sentry.ErrorBoundary>
  );
};

const Fallback = ({ child, href }) => {
  // Support links that used to be images
  if (child?.props?.alt) {
    return <Anchor href={href}>{child.props.alt}</Anchor>;
  } else {
    // Not really sure what it is so just fail
    // The Anchor handles most other messed up links
    return <p>Failed to render link.</p>;
  }
};

const failedAnchor = ({
  children,
  className,
  href,
  label,
  eventName,
  rest,
  aTagProps,
  onClick,
  prefetch,
}) => {
  // eslint-disable-next-line no-console
  console.error('Anchor will fail', {
    children,
    className,
    href,
    label,
    eventName,
    prefetch,
    rest,
  });
  return (
    <a
      href={href}
      className={className}
      {...rest}
      {...aTagProps}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

Anchor.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
  /**
   * Send an analytics event with this name on click.
   */
  eventName: PropTypes.string,
  /**
   * Prefetch the page on hover (used to control the native NextJS Link behavior).
   */
  prefetch: PropTypes.bool,
};

export default Anchor;
