import * as Sentry from '@sentry/nextjs';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Head from 'next/head';
import isEmpty from 'lodash/isEmpty';

import Container from 'components/container';
import Error from 'components/error';
import Footer from 'components/footer';
import Header from 'components/header';
// This is a duplicate file from app router
import defaultShareImage from 'images/shares/default.png';
import {
  icon48,
  icon72,
  icon96,
  icon144,
  icon168,
  icon192,
  icon256,
  icon384,
  icon512,
} from 'images/icons/iconPaths';
import { ISODateValidator } from 'utilities/proptype-validators';
import config from 'siteconfig';

const THEME_COLOR = '#0074e0';
const defaultShareImageAltText =
  'Illustration of Rosie the Riveter in front of the U.S. Capitol with faxes and postal mail flying towards it. Overlay text reads, "text resist to 50409"';

// TODO: Need to port this entry to any pages moved to app router
// Maps our content to the OG standard types
const DATA_TYPES = {
  blog: 'article',
  petition: 'resistbot:petition',
  letter: 'resistbot:letter',
  drive: 'resistbot:drive',
  website: 'website',
};
const SITE_URL = config.siteUrl;

const Layout = ({
  description = null,
  meta = [],
  keywords = [],
  title = null,
  image = null,
  imageAltText = null,
  dynamicImage,
  imageWidth,
  imageHeight,
  dataType = DATA_TYPES.website,
  ogTitle,
  ogDescription,
  publishedOn,
  updatedOn,
  // Could be used by any/all pages if there's possibility of misformed URLs being shared around (petitions)
  canonicalPath,
  // Some sort of special header/banner, like the homepage has
  header,
  // Elements it encloses
  children,
}) => {
  // Image might be a string, or an image Object. Need to extract just the raw URL in either case
  const staticImage = image?.src || image;
  const router = useRouter();
  const canonical = canonicalPath
    ? new URL(canonicalPath, config.siteUrl).href
    : new URL(router.asPath, config.siteUrl).href.split('?')[0];
  const seo = {
    title: title || config.title,
    description: description || config.description,
    image: new URL(staticImage || defaultShareImage?.src, config.siteUrl).href,
    dynamicImage: dynamicImage,
    imageAltText: imageAltText || defaultShareImageAltText,
    url: canonical,
    author: config.blog.authorTwitterUsername,
    ogTitle: ogTitle || title || config.title,
    ogDescription: ogDescription || description || config.description,
  };

  let jsonLd = null;

  if (dataType === DATA_TYPES.blog) {
    jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      headline: title || config.title,
      image: [new URL(staticImage || defaultShareImage, config.siteUrl).href],
      datePublished: new Date(publishedOn).toISOString(),
      dateModified: new Date(updatedOn).toISOString(),
      author: [
        {
          '@type': 'Organization',
          name: 'Resistbot',
          url: 'https://resist.bot',
        },
      ],
    };
  }

  const _meta = [
    {
      name: `description`,
      content: seo.description,
    },
    {
      property: 'og:url',
      content: seo.url,
    },
    {
      property: `og:title`,
      content: seo.ogTitle,
    },
    {
      property: `og:description`,
      content: seo.ogDescription,
    },
    {
      property: 'og:type',
      content: dataType,
    },
    {
      property: `og:image`,
      content: seo.dynamicImage || seo.image,
    },
    {
      property: `og:image:alt`,
      content: seo.imageAltText,
    },
    {
      property: `og:site_name`,
      content: `Resistbot`,
    },
    // Twitter Specific (they use `name` rather than `property`)
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: seo.ogTitle,
    },
    {
      name: `twitter:description`,
      content: seo.ogDescription,
    },
    {
      name: `twitter:image`,
      content: seo.dynamicImage || seo.image,
    },
    {
      name: `twitter:image:alt`,
      content: seo.imageAltText,
    },
    {
      name: `twitter:site`,
      content: seo.author,
    },
  ]
    .concat(
      imageWidth
        ? {
            name: 'og:image:width',
            content: imageWidth,
          }
        : null
    )
    .concat(
      imageHeight
        ? {
            name: 'og:image:height',
            content: imageHeight,
          }
        : null
    )
    .concat(
      keywords && keywords.length > 0
        ? {
            name: `keywords`,
            content: keywords.join(`, `),
          }
        : []
    )
    .concat(
      dataType === DATA_TYPES.blog && publishedOn
        ? {
            property: 'article:published_time',
            content: publishedOn,
          }
        : []
    )
    .concat(
      dataType === DATA_TYPES.blog && updatedOn
        ? {
            property: 'article:modified_time',
            content: updatedOn,
          }
        : []
    )
    // Theme Color sets the menu bar color on some androids and Safari
    .concat({ name: 'theme-color', content: THEME_COLOR })
    .concat(meta);

  const metaElements = _meta.map((element) => {
    if (!isEmpty(element)) {
      const keyName = element.property ? 'property' : 'name';
      const content = element.content;
      return (
        <meta
          key={element[keyName]}
          {...{ [keyName]: element[keyName], content }}
        />
      );
    }
  });

  return (
    <>
      <Head>
        <title>{seo.title}</title>
        <meta charSet="utf-8" />
        <link rel={'canonical'} href={seo.url} />
        {metaElements.map((metaElement) => metaElement)}
        <link rel="me" href="https://botsin.space/@resistbot" />
        <link rel="me" href="https://mstdn.social/@resistbotatwork" />
        <link
          rel="alternate"
          type="application/rss+xml"
          href={`${SITE_URL}/feed.xml`}
        />
        <link
          rel="alternate"
          type="application/json"
          href={`${SITE_URL}/feed.json`}
        />
        <link
          rel="alternate"
          type="application/atom+xml"
          href={`${SITE_URL}/feed.atom`}
        />
        <link rel="icon" type="image/png" href={icon48} />
        <link rel="apple-touch-icon" sizes="32x32" href={icon48} />
        <link rel="apple-touch-icon" sizes="48x48" href={icon48} />
        <link rel="apple-touch-icon" sizes="72x72" href={icon72} />
        <link rel="apple-touch-icon" sizes="96x96" href={icon96} />
        <link rel="apple-touch-icon" sizes="144x144" href={icon144} />
        <link rel="apple-touch-icon" sizes="168x168" href={icon168} />
        <link rel="apple-touch-icon" sizes="192x192" href={icon192} />
        <link rel="apple-touch-icon" sizes="256x256" href={icon256} />
        <link rel="apple-touch-icon" sizes="384x384" href={icon384} />
        <link rel="apple-touch-icon" sizes="512x512" href={icon512} />
        {jsonLd && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(jsonLd),
            }}
          ></script>
        )}
      </Head>
      <Header siteTitle={config.title}>{header}</Header>
      <main className="pt-4">
        {/* This boundary handles anything we missed at a more granular level. */}
        {/* The header and footer will still be operational. */}
        <Sentry.ErrorBoundary fallback={<Error />}>
          <Container>{children}</Container>
        </Sentry.ErrorBoundary>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ src: PropTypes.string.isRequired }),
  ]),
  dynamicImage: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  imageAltText: PropTypes.string,
  dataType: PropTypes.oneOf(Object.values(DATA_TYPES)),
  ogDescription: PropTypes.string,
  ogTitle: PropTypes.string,
  publishedOn: ISODateValidator,
  updatedOn: ISODateValidator,
  canonicalPath: PropTypes.string,
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
};

export { DATA_TYPES };
export default Layout;
