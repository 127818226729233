import * as Fathom from 'fathom-client';
import * as Sentry from '@sentry/nextjs';

import { RECURRING_PERIODS } from 'lib/stripe';

const LIFETIME_VALUE_MULTIPLIER_MONTHLY = 24;
const LIFETIME_VALUE_MULTIPLIER_YEARLY = 2;
const FATHOM_DONATION_EVENT_RECURRING = 'Premium';
const FATHOM_DONATION_EVENT_ONETIME = 'Coin Purchase';
const FATHOM_SIGNUP_EVENT_FB_MESSENGER = 'Messenger';
const FATHOM_SIGNUP_EVENT_IMESSAGE = 'Apple Messages';
const FATHOM_SIGNUP_EVENT_INSTA = 'Instagram';
const FATHOM_SIGNUP_EVENT_SMS = 'SMS';
const FATHOM_SIGNUP_EVENT_TELEGRAM = 'Telegram';
const FATHOM_SIGNUP_EVENT_TWITTER = 'Twitter';
const FATHOM_SIGNUP_EVENT_WHATSAPP = 'WhatsApp';

export const donationTrackEvent = ({
  recurringPeriod = null,
  donationValueCents,
}) => {
  if (recurringPeriod === RECURRING_PERIODS.MONTH) {
    Fathom.trackEvent(FATHOM_DONATION_EVENT_RECURRING, {
      _value: donationValueCents * LIFETIME_VALUE_MULTIPLIER_MONTHLY,
    });
  } else if (recurringPeriod === RECURRING_PERIODS.YEAR) {
    Fathom.trackEvent(FATHOM_DONATION_EVENT_RECURRING, {
      _value: donationValueCents * LIFETIME_VALUE_MULTIPLIER_YEARLY,
    });
  } else {
    Fathom.trackEvent(FATHOM_DONATION_EVENT_ONETIME, {
      _value: donationValueCents,
    });
  }
};

/**
 * Track a generic event
 * @param  {string} eventName The name of the event
 * @param  {number} eventValue The monetary value of the event in cents, otherwise defaults to 0
 * @return {null}     Runs optimistically
 */
export const trackEvent = ({ eventName, eventValue = 0 }) => {
  // Report bad inputs to sentry
  if (!eventName) {
    Sentry.captureException(
      new Error('trackEvent: missing eventName', eventName, eventValue)
    );
  }

  try {
    if (!eventValue) {
      Fathom.trackEvent(eventName);
    } else {
      Fathom.trackEvent(eventName, { _value: eventValue });
    }
  } catch (error) {
    Sentry.captureException(new Error('trackEvent: Fathom Error', error));
  }
};

export {
  FATHOM_DONATION_EVENT_RECURRING,
  FATHOM_DONATION_EVENT_ONETIME,
  FATHOM_SIGNUP_EVENT_FB_MESSENGER,
  FATHOM_SIGNUP_EVENT_IMESSAGE,
  FATHOM_SIGNUP_EVENT_INSTA,
  FATHOM_SIGNUP_EVENT_SMS,
  FATHOM_SIGNUP_EVENT_TELEGRAM,
  FATHOM_SIGNUP_EVENT_TWITTER,
  FATHOM_SIGNUP_EVENT_WHATSAPP,
};
