import cx from 'classnames';
import PropTypes from 'prop-types';
import Img from 'next/image';
import { forwardRef } from 'react';

import useMediaQuery from 'hooks/useMediaQuery';
import { trackEvent } from 'utilities/analytics';
import { ImageDataURLValidator } from 'utilities/proptype-validators';

const Button = forwardRef(
  (
    {
      children,
      className,
      href,
      iconAlt = '',
      iconSrc,
      theme,
      eventName,
      preventDefault = false,
      ...props
    },
    ref
  ) => {
    const [As, asProps] = href ? ['a', { href }] : ['button', {}];
    const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');

    const onClickFns = [];

    // Existing uses provide this prop directly, so add those first.
    if (props.onClick) {
      onClickFns.push(props.onClick);
    }

    // Add in the analytics tracking function when an event ID is provided.
    if (eventName) {
      onClickFns.push(() => {
        trackEvent({ eventName });
      });
    }

    // We will now run all of these as needed rather than a single one passed in
    const onClick = () => {
      if (preventDefault) {
        event.preventDefault();
      }
      onClickFns.forEach((fn) => fn());
    };

    let buttonStyle = '';
    switch (theme) {
      case 'light':
        buttonStyle = 'button-light';
        break;
      case 'animated':
        buttonStyle = 'button-animated';
        break;
      case 'blue':
        buttonStyle = 'button-blue';
        break;
      case 'outline':
        buttonStyle = 'button-outline';
        break;
      case 'medium':
        buttonStyle = 'button-medium';
        break;
      case 'large':
        buttonStyle = 'button-large';
        break;
      case 'full':
        buttonStyle = 'button-full';
        break;
      case 'tab':
        buttonStyle = 'button-tab';
        break;
      case 'navigation':
        buttonStyle = 'button-navigation';
        break;
      case 'link':
        buttonStyle = 'button-link';
        break;
      default:
        buttonStyle = '';
    }

    return (
      <As
        {...props}
        {...asProps}
        className={cx(className, buttonStyle)}
        ref={ref}
        onClick={onClick}
      >
        {iconSrc && (
          <Img
            src={iconSrc}
            alt={iconAlt}
            height="13"
            width="13"
            style={{
              filter:
                prefersDark || theme == 'blue' || theme == 'animated'
                  ? 'opacity(50%) invert(100%) sepia(100%) saturate(20%) hue-rotate(309deg) brightness(105%) contrast(100%)'
                  : 'opacity(50%)',
            }}
          />
        )}
        {children}
      </As>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  /**
   * Any content to enclose, usually plain text
   */
  children: PropTypes.node.isRequired,
  /**
   * Additional class to apply to this text. Use sparingly to apply specialized styles.
   */
  className: PropTypes.string,
  /**
   * A link for when the button is clicked. Not used when the button uses listeners to trigger other actions (such as a payment form).
   */
  href: PropTypes.string,
  /**
   * Descriptive Alt Text for the icon in the button
   */
  iconAlt: PropTypes.string,
  /**
   * Icon source
   */
  iconSrc: PropTypes.oneOfType([
    PropTypes.exact({
      src: PropTypes.string.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      blurDataURL: PropTypes.string.isRequired,
    }),
    ImageDataURLValidator,
    // Sometimes the iconSrc is jsut a URL string like '/images/icons/arrow.svg'
    PropTypes.string,
  ]),
  /**
   * Adjust the size of the button.
   */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  /**
   * Adjust the color and appearance of the button.
   */
  theme: PropTypes.oneOf([
    'blue',
    'light',
    'outline',
    'animated',
    'medium',
    'large',
    'full',
    'tab',
    'navigation',
    'link',
  ]).isRequired,
  /**
   * Send an analytics event with this name on click.
   */
  eventName: PropTypes.string,
  /**
   * Prevent the default action of the button (such as navigating to a link)
   * when clicked. Use with buttons that are not for normal navigation (payment form buttons).
   * @default false
   * @type {boolean}
   */
  preventDefault: PropTypes.bool,
};

export default Button;
