'use client';

import { useState } from 'react';
import Anchor from 'components/typography/anchor';
import { NavModal, NavToggle } from 'components/header/NavModal';

export const Nav = () => {
  const [navIsOpen, setNavOpen] = useState(false);
  const openModal = () => {
    setNavOpen(true);
  };
  const closeModal = () => {
    setNavOpen(false);
  };

  return (
    <>
      <NavToggle onClick={openModal} />

      <nav
        className="flex h-16 items-center justify-center gap-2"
        aria-label="primary"
      >
        <Anchor className="anchor-subtle" href="/premium/coins">
          Buy Coins
        </Anchor>
        <Anchor className="anchor-subtle" href="/petitions">
          Petitions
        </Anchor>
        <Anchor className="anchor-subtle hidden sm:inline" href="/letters">
          Letters
        </Anchor>
        <Anchor className="anchor-subtle" href="/guide">
          Guide
        </Anchor>
        <Anchor className="anchor-subtle hidden sm:inline" href="/about">
          Help
        </Anchor>
      </nav>

      <NavModal navIsOpen={navIsOpen} closeModalFn={closeModal} />
    </>
  );
};

export default Nav;
