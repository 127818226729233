// This is the model for a customer that we use around the site.
// It's roughly structured around parts of the Stripe Customer object
// but with parts we don't need omitted.
const EMPTY_CUSTOMER = {
  customer: null,
  setupIntent: {
    clientSecret: null,
  },
  paymentMethod: null,
  clientSecret: null,
  attempts: {
    search: {
      attempts: 0,
      time: 0,
    },
    direct: {
      attempts: 0,
      time: 0,
    },
  },
};

const hasActiveSubscription = (customer) => {
  return customer?.subscription?.active;
};

export { hasActiveSubscription, EMPTY_CUSTOMER };
