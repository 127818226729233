// A mapping of where these images _should_ be stored, relative to the generated site root
module.exports.icon48 = '/favicons/icon-48x48.png';
module.exports.icon72 = '/favicons/icon-72x72.png';
module.exports.icon96 = '/favicons/icon-96x96.png';
module.exports.icon144 = '/favicons/icon-144x144.png';
module.exports.icon168 = '/favicons/icon-168x168.png';
module.exports.icon192 = '/favicons/icon-192x192.png';
module.exports.icon256 = '/favicons/icon-256x256.png';
module.exports.icon384 = '/favicons/icon-384x384.png';
module.exports.icon512 = '/favicons/icon-512x512.png';
