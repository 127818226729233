// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#custom_error_types

class UnknownPaymentMethodError extends Error {
  constructor(object, type, ...params) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownPaymentMethodError);
    }

    this.name = 'UnknownPaymentMethodError';
    this.object = object;
    this.type = type;
  }
}

export { UnknownPaymentMethodError };
