'use client';

import Img from 'next/image';

import useMediaQuery from 'hooks/useMediaQuery';
import botHeadLight from 'images/logos/logo-bot-dark.svg';
import botHeadDark from 'images/logos/logo-bot-white.svg';
import wordmarkLight from 'images/logos/wordmark-dark.svg';
import wordmarkDark from 'images/logos/wordmark-white.svg';

const HomeAnchorImage = ({ siteTitle }) => {
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  return prefersDark ? (
    <>
      <Img
        height="32"
        width="32"
        src={botHeadDark}
        alt={siteTitle}
        className="block sm:hidden"
      />
      <Img
        height="32"
        width="124"
        src={wordmarkDark}
        alt={siteTitle}
        className="hidden sm:block"
      />
    </>
  ) : (
    <>
      <Img
        height="32"
        width="32"
        src={botHeadLight}
        alt={siteTitle}
        className="block sm:hidden"
      />
      <Img
        height="32"
        width="124"
        src={wordmarkLight}
        alt={siteTitle}
        className="hidden sm:block"
      />
    </>
  );
};

export default HomeAnchorImage;
