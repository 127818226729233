export const BuildData = () => {
  const commit = process.env.COMMIT_REF;
  const branch = process.env.BRANCH;
  const buildDate = process.env.BUILD_DATETIME_HUMAN;
  return branch && commit && buildDate ? (
    <>
      <p className="text-xs leading-5" aria-hidden="true">
        Version{' '}
        <code className="rounded border border-stone-200 p-1 dark:border-stone-700">
          {branch}/{commit}
        </code>{' '}
        built with{' '}
        <span role="img" aria-label="love">
          ❤️
        </span>{' '}
        on {buildDate} by robots without emotions.
      </p>
    </>
  ) : null;
};

export default BuildData;
