import { parseISO } from 'date-fns';

export const ISODateValidator = (props, propName, componentName) => {
  const prop = props[propName];

  // Props that were not set are `undefined`, this validator will not throw on those.
  // Makes it "optional"
  if (prop && typeof prop !== 'undefined') {
    const parsedDate = parseISO(prop);

    // The test date-fns uses in their own tests: https://github.com/date-fns/date-fns/blob/54ec0a5e5ca3f472085d3b56be30d801b90829ac/src/parseISO/test.ts#L228-L235
    if (isNaN(parsedDate.getTime())) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed: the input \`${prop}\` can't be parsed as ISO 8601`
      );
    }
  }
};

export const ISODateValidatorRequired = (props, propName, componentName) => {
  const prop = props[propName];

  // Props that were not set are `undefined`, this validator will not throw on those.
  // Makes it "optional"
  if (!prop || typeof prop === 'undefined') {
    return new Error(
      `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${prop}\`.`
    );
  }
  const parsedDate = parseISO(prop);
  // The test date-fns uses in their own tests: https://github.com/date-fns/date-fns/blob/54ec0a5e5ca3f472085d3b56be30d801b90829ac/src/parseISO/test.ts#L228-L235
  if (isNaN(parsedDate.getTime())) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed: the input \`${prop}\` can't be parsed as ISO 8601`
    );
  }
};

export const ImageDataURLValidator = (props, propName, componentName) => {
  // Validates that the string looks like a data url, if it's a string, and defined.
  const prop = props[propName];

  // Props that were not set are `undefined`, this validator will not throw on those.
  // Makes it "optional"
  if (prop && typeof prop === String) {
    if (!prop.startsWith('data:image')) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed: the input \`${prop}\` does not start with 'data:image'`
      );
    }
  }
};
