import Anchor from 'components/typography/anchor';
import Button from 'components/buttons/button';
import BotImage from 'components/botimage';
import pridebot from 'images/bots/pridebot.svg';
import config from 'siteconfig';

const Error = () => {
  return (
    <section className="text-center">
      <BotImage src={pridebot} />
      <h1 className="mb-2 mt-4">I Ran Into an Error</h1>

      <p className="mb-2">
        Try{' '}
        <Anchor
          className="anchor-text"
          href="/"
          onClick={() => {
            location.reload();
          }}
        >
          reloading this page
        </Anchor>{' '}
        first. If you see this message again,{' '}
        <Anchor
          className="anchor-text"
          href={'mailto:' + config.emailAddresses.support}
        >
          contact support
        </Anchor>
        . Thank&nbsp;you!
      </p>
      {/* An anchor that just has an onClick that refreshes the page */}
      <Button
        className="mb-10"
        theme="blue"
        onClick={() => {
          location.reload();
        }}
      >
        Reload
      </Button>
    </section>
  );
};

export { Error };
export default Error;
