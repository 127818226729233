import PropTypes from 'prop-types';

import Container from 'components/container';
import HomeAnchorImage from 'components/header/HomeAnchorImage';
import Anchor from 'components/typography/anchor';

import { Nav } from './Nav';

const Header = ({ children, siteTitle }) => {
  return (
    <header className="relative min-h-16 border-b border-black/10 bg-transparent text-center dark:border-white/10 dark:bg-indigo-950/50 print:hidden">
      <Anchor
        label="Resistbot Home"
        href="/"
        className="absolute left-4 top-4 h-16"
      >
        <HomeAnchorImage siteTitle={siteTitle} />
      </Anchor>
      <Nav />
      <Container>{children}</Container>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Header;
